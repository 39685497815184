<template>
  <backoffice-container>
    <div>
      <b-card class="mt-2">
        
        <!-- Section title -->
        <div class="w-100 my-1 mb-2 float-left pl-1">
          <div class="d-flex align-items-center float-left">
            <a class="mr-50" 
              @click="$router.push({name: 'appstore'})"
            >
              <feather-icon icon="ChevronLeftIcon" size="20" />
            </a>
            <h3 class="m-0 font-weight-600">
              Apps
            </h3>
          </div>
        </div>

        <!-- Loading -->
        <div v-if="isLoading">
          <b-spinner
            type="grow"
            small
            class="mx-auto mt-3 d-block text-primary"
          />
        </div>

        <!-- Container with data -->
        <div v-else-if="item" class="cards-container">
          <b-card-body>
            <b-row class="my-2">
              <!-- LEFT: Product Image Container -->
              <b-col
                cols="12"
                md="5"
                class="align-items-center justify-content-center mb-2 mb-md-0"
              >
                <!-- Image -->
                <div class="d-flex align-items-center justify-content-center">
                  <safe-img
                    :src="getImageSrc(item.imageUrl)"
                    :placeholder="Placeholder"
                    :alt="`Image of ${
                      item.name[currentLocale] || Object.values(item.name)[0]
                    }`"
                    style="border-radius: 1.2rem; width: 600px; height: 160px"
                    fluid
                  />
                </div>

                <!-- ACTION BUTTONS -->
                <div class="mt-2 actions-container">
                  <!-- INSTALL -->
                  <div
                    v-b-tooltip.hover
                    :title="$t('tooltips.addons.install')"
                    class="special-action"
                  >
                    <b-form-checkbox
                      v-model="item.isInstalled"
                      switch
                      :disabled="loading"
                      class="d-inline-block mr-2"
                      @change="changeToggleButton(item.key, 'install')"
                    />
                    <label
                      for="rol-name"
                      class="font-weight-bold d-inline-block mr-1"
                    >
                      {{ $t("addons.installed") }}
                    </label>
                  </div>
                  <!-- PUBLISH/ACTIVATE APP -->
                  <div
                    v-if="item.isInstalled"
                    v-b-tooltip.hover
                    :title="$t('tooltips.addons.publish')"
                    class="mt-1 pt-1 special-action"
                  >
                    <b-form-checkbox
                      v-model="item.isPublished"
                      class="d-inline-block mr-2"
                      switch
                      :disabled="loading"
                      @change="changeToggleButton(item.key, 'publish')"
                    />
                    <label
                      for="rol-name"
                      class="font-weight-bold d-inline-block mr-1"
                    >
                      {{ $t("addons.published") }}
                    </label>
                  </div>
                  <!-- SHOW IN MENU FROM DASHBOARD -->
                  <div
                    v-if="item.isInstalled && item.inMenu"
                    v-b-tooltip.hover
                    :title="$t('tooltips.addons.menu')"
                    class="mt-1"
                  >
                    <b-form-checkbox
                      v-model="item.isInMenu"
                      switch
                      :disabled="loading"
                      class="d-inline-block mr-2"
                      @change="changeToggleButton(item.key, 'show')"
                    />
                    <label
                      for="rol-name"
                      class="font-weight-bold d-inline-block mr-1"
                    >
                      {{ $t("addons.in-menu") }}
                    </label>
                  </div>
                  <!-- SHOW IN LAYOUT FROM DASHBOARD -->
                  <div
                    v-if="item.isInstalled && item.isWidget"
                    v-b-tooltip.hover
                    :title="$t('tooltips.addons.layout')"
                    class="mt-1"
                  >
                    <b-form-checkbox
                      v-model="item.isInLayout"
                      switch
                      :disabled="loading"
                      class="d-inline-block mr-2"
                      @change="changeToggleButton(item.key, 'Layout')"
                    />
                    <label
                      for="rol-name"
                      class="font-weight-bold d-inline-block mr-1"
                    >
                      {{ $t("addons.in-layout") }}
                    </label>
                  </div>
                  <!-- SHOW IN MENU FROM PREVIEW -->
                  <div
                    v-if="item.isInstalled && canBeInPreview"
                    class="mt-1"
                  >
                    <b-form-checkbox
                      v-model="item.isInPreview"
                      switch
                      :disabled="loading"
                      class="d-inline-block mr-2"
                      @change="changeToggleButton(item.key, 'Preview')"
                    />
                    <label
                      for="rol-name"
                      class="font-weight-bold d-inline-block mr-1"
                    >
                    {{ $t("addons.in-preview") }}
                    </label>
                  </div>
                </div>

              </b-col>
              <!-- RIGHT: Product Details -->
              <b-col cols="12" md="7">
                <!-- Addon Name -->
                <div class="mb-1">
                  <h3 class="mb-0">
                    {{
                      item.name[currentLocale] || Object.values(item.name)[0]
                    }}
                  </h3>
                </div>
                <p
                  v-if="item && item.tagline"
                  class="mb-2"
                  v-html="
                    item.tagline[currentLocale]
                      ? item.tagline[currentLocale]
                      : Object.values(item.tagline)[0]
                  "
                />
                
                <!-- Display options -->
                <div
                  v-if="
                    [
                      'events',
                      'entities',
                      'people',
                      'organizations',
                      'challenges',
                    ].includes(item.key)
                  "
                >
                  <div
                    v-if="
                      ((item.customizations &&
                        ((item.customizations.displayOptions === 2 &&
                          types.length > 0) ||
                          item.customizations.displayOptions === 3 ||
                          item.customizations.displayOptions === 1)) ||
                        (item.key === 'events' &&
                          item.customizations.displayOptions >= 0)) &&
                      translate(item.customizations.customizationName) !==
                        translate(item.name)
                    "
                    class="my-2"
                  >
                    <h5 class="mb-50">
                      {{ $t("addons.custom-name") }}
                      <b-button
                        v-if="
                          !item.customizations.displayOptions ||
                          ([
                            'entities',
                            'people',
                            'organizations',
                            'challenges',
                          ].includes(item.key) &&
                            (item.customizations.displayOptions <= 1 ||
                              (item.customizations.customizationName &&
                                Object.keys(
                                  item.customizations.customizationName
                                ).length === 1)))
                        "
                        variant="link"
                        class="p-0 small ml-1"
                        @click="openModal()"
                      >
                        <feather-icon icon="Edit2Icon" size="16" />
                      </b-button>
                      <b-button
                        variant="link"
                        class="p-0 small ml-75"
                        @click="isUnshareModalVisible = true"
                      >
                        <b-spinner
                          v-if="isDeleting"
                          class="mr-1"
                          small
                          variant="primary"
                          label="Loading..."
                        />
                        <feather-icon v-else icon="TrashIcon" size="16" />
                      </b-button>
                    </h5>
                    <div
                      v-if="
                        ([
                          'entities',
                          'people',
                          'organizations',
                          'challenges',
                        ].includes(item.key) &&
                          item.customizations.displayOptions > 1 &&
                          customsToGet.customizationName &&
                          Object.keys(customsToGet.customizationName).length >
                            0) ||
                        (item.key === 'events' &&
                          item.customizations.displayOptions)
                      "
                    >
                      <div
                        v-for="(
                          name, index
                        ) in customsToGet.customizationName"
                        :key="index"
                        class="d-flex"
                      >
                        <p class="mb-50">
                          {{ translate(name.name) }} :
                          {{
                            translate(name.customizations) ||
                            translate(name.name)
                          }}
                        </p>
                        <b-button
                          variant="link"
                          class="p-0 small ml-1"
                          @click="openModal(name)"
                        >
                          <feather-icon icon="Edit2Icon" size="16" />
                        </b-button>
                      </div>
                    </div>
                    <div
                      v-else-if="
                        ([
                          'entities',
                          'people',
                          'organizations',
                          'challenges',
                        ].includes(item.key) &&
                          item.customizations.displayOptions === 1) ||
                        (item.key === 'events' &&
                          !item.customizations.displayOptions)
                      "
                      class="d-flex"
                    >
                      <p class="mb-50">
                        {{
                          translate(item.customizations.customizationName) ||
                          translate(customsToGet.customizationName.noKey) ||
                          translate(customsToGet.customizationName.name)
                        }}
                      </p>
                    </div>

                    <b-button
                      v-else
                      variant="link"
                      class="p-0 my-1 d-flex align-items-center"
                      @click="openModal()"
                    >
                      <p class="mb-0 mr-50">
                        {{ $t("addons.create-custom-name") }}
                      </p>
                      <feather-icon icon="Edit2Icon" size="16" />
                    </b-button>
                  </div>
                  <b-button
                    v-else
                    variant="link"
                    class="p-0 my-1 d-flex align-items-center"
                    @click="openModal()"
                  >
                    <p class="mb-0 mr-50">
                      {{ $t("addons.create-custom-name") }}
                    </p>
                    <feather-icon icon="Edit2Icon" size="16" />
                  </b-button>
                </div>
                <div v-else>
                  <div
                    v-if="
                      item.customizations &&
                      item.customizations.customizationName &&
                      Object.keys(item.customizations.customizationName)
                        .length > 0 &&
                      translate(item.customizations.customizationName) !==
                        translate(item.name)
                    "
                    class="my-2"
                  >
                    <h5 class="mb-50">
                      {{ $t("addons.custom-name") }}
                      <b-button
                        variant="link"
                        class="p-0 small ml-1"
                        @click="openModal()"
                      >
                        <feather-icon icon="Edit2Icon" size="16" />
                      </b-button>
                      <b-button
                        variant="link"
                        class="p-0 small ml-75"
                        @click="isUnshareModalVisible = true"
                      >
                        <b-spinner
                          v-if="isDeleting"
                          class="mr-1"
                          small
                          variant="primary"
                          label="Loading..."
                        />
                        <feather-icon v-else icon="TrashIcon" size="16" />
                      </b-button>
                    </h5>
                    <p>
                      {{ translate(item.customizations.customizationName) }}
                    </p>
                  </div>
                  <b-button
                    v-else
                    variant="link"
                    class="p-0 my-1 d-flex align-items-center"
                    @click="openModal()"
                  >
                    <p class="mb-0 mr-50">
                      {{ $t("addons.create-custom-name") }}
                    </p>
                    <feather-icon icon="Edit2Icon" size="16" />
                  </b-button>
                </div>

                <!-- DISPLAY OPTIONS -->
                <div
                  v-if="item.isInstalled && hasDisplayOptions"
                  class="mt-2 mb-3"
                >
                  <h5>
                    {{ this.$t("display-type-options.title") }}
                  </h5>
                  <div class="mt-1">
                    <v-select
                      v-model="displayOptionSelected"
                      class="select-control selection"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.code"
                      :searchable="false"
                      :options="displayOptions"
                    />
                  </div>
                  <div v-if="isMedia" class="mt-2">
                    <h5>
                      {{ this.$t("display-type-options.collaborators") }}
                    </h5>

                    <div class="mt-1">
                      <v-select
                        v-model="collaboratorOptionSelected"
                        class="select-control selection"
                        :clearable="false"
                        label="name"
                        :placeholder="$t('form.select-placeholder')"
                        :reduce="(option) => option.code"
                        :searchable="false"
                        :options="displayCollaboratorOptions"
                      />
                    </div>
                  </div>
                  <div v-if="isOrganization || isMember" class="mt-2">
                    <h5>
                      {{ this.$t("addons.organizations.default-content") }}
                    </h5>
                    <div class="mt-1">
                      <v-select
                        v-model="organizationOptionSelected"
                        class="select-control selection"
                        :clearable="false"
                        label="name"
                        :placeholder="$t('form.select-placeholder')"
                        :reduce="(option) => option.code"
                        :searchable="false"
                        :options="displayVisibility"
                      />
                    </div>
                  </div>
                  <b-button
                    :key="keyToRerender"
                    variant="primary"
                    class="mt-1"
                    size="sm"
                    :disabled="isSaving"
                    @click="changeTagline"
                  >
                    <span v-if="isSaving" class="d-flex"
                      ><b-spinner
                        class="mr-1"
                        small
                        variant="white"
                        label="Loading..."
                      />
                      {{ $t("form.actions.save") }}
                    </span>
                    <span v-else>{{ $t("form.actions.save") }}</span>
                  </b-button>
                </div>

                <!-- Description -->
                <b-card-text class="mt-2"/>
                  <span v-html="getDescription(item)"></span>
                </b-col>
            </b-row>
          </b-card-body>
          <!-- Custom app name modal -->
          <custom-addon-name-modal
            v-model="isEditingName"
            :item="item"
            :editing-type="editingType"
          />
        </div>

        <!-- Container without data: Placeholder -->
        <b-row v-else class="horizontal-placeholder">
          <b-col cols="12">
            <img :src="Placeholder" />
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{ $t("available.message", { itemName: "items" }) }}
            </p>
          </b-col>
        </b-row>

      </b-card>
      <unshare-modal
        v-model="isUnshareModalVisible"
        model-type="custom-addon"
        :custom-message="unshareMessage"
        @remove-item="handleRemoveItem"
        @modal-invisible="isUnshareModalVisible = false"
      />
    </div>
  </backoffice-container>
</template>

<script>
import { getImageResource } from "@/@core/utils/image-utils";
import Placeholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue";
import SafeImg from "@core/components/safe-img/SafeImg.vue";
import { BFormCheckbox } from "bootstrap-vue";
import vSelect from "vue-select";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import CustomAddonNameModal from "@core/components/modal/CustomAddonNameModal.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import UnshareModal from "@/@core/components/modal/UnshareModal.vue";
import { randomString } from "@/@core/utils/utils";

export default {
  name: "AddonDetails",
  components: {
    BackofficeContainer,
    SafeImg,
    BFormCheckbox,
    vSelect,
    CustomAddonNameModal,
    UnshareModal,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      memberForMeeting: {},
      formInput: {},
      formError: null,
      itemImage: null,
      imageSrc: null,
      check: false,
      loading: false,
      switchLoading: false,
      displayOptionSelected: 0,
      collaboratorOptionSelected: true,
      isEditingName: false,
      isUnshareModalVisible: false,
      editingType: {},
      isSaving: false,
      keyToRerender: "Key",
      organizationOptionSelected: "all",
      isDeleting: false,
      addonsInPreview: [
          "agenda",
          "members",
          "entities",
          "people",
          "ideas",
          "products",
          "services",
          "challenges",
          "courses",
          "jobs",
          "contacts",
          "societies",
          "projects",
          "sections",
          "subcommunities",
          "events",
          "documents",
          "presentations",
          "spreadsheets",
          "videos",
          "photos",
          "audios",
        ],
      addonsWithByType: [
          "members",
          "people",
          "entities",
          "events",
          "organizations",
          "challenges",
        ],
        appsWithDisplayOptions: [
          "members",
          "entities",
          "people",
          "organizations",
          "events",
          "videos",
          "documents",
          "audios",
          "photos",
          "presentations",
          "spreadsheets",
          "auditorium",
          "map",
          "challenges",
        ],
        mediaApps: [
          "videos",
          "documents",
          "audios",
          "photos",
          "presentations",
          "spreadsheets",
        ],
    };
  },
  computed: {
    appKey() {
      return this.$route.params.id;
    },
    item() {
      return this.$store.getters.item("addons", this.appKey);
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    isNew() {
      return (
        //TODO: review, this is not well done...
        new Date(this.collective.createdAt) >=
        new Date(
          "Wed Jan 11 2023 15:02:21 GMT+0100 (hora estándar de Europa central)"
        )
      );
    },
    enabledApps() {
      return this.$store.getters.installedApps?.data?.map(
        (item) => item.addonSingular
      );
    },
    enabledOrganizationApp() {
      return (
        this.enabledApps?.filter((item) => item === "organization").length > 0
      );
    },
    enabledMembersApp() {
      return this.enabledApps?.filter((item) => item === "member").length > 0;
    },
    enabledEventsApp() {
      return this.enabledApps?.filter((item) => item === "event").length > 0;
    },
    enabledSocietiesApp() {
      return this.enabledApps?.filter((item) => item === "society").length > 0;
    },
    isOrganization() {
      return this.item?.key === "organizations";
    },
    isMember() {
      return this.item?.key === "members";
    },
    canBeInPreview() {
      return (this.addonsInPreview.includes(this.item?.key));
    },
    unshareMessage() {
      return this.$t("addons.unshare-message");
    },
    displayOptions() {
      // La opción que se quiera por defecto tiene que ser la primera del array
      if (this.appKey === "events") {
        return [
          { name: this.$t("display-type-options.events.together"), code: 0 },
          { name: this.$t("display-type-options.events.apart"), code: 1 },
          { name: this.$t("display-type-options.events.mix"), code: 2 },
        ];
      }
      if (this.addonsWithByType.includes(this.appKey)) {
        return [
          { name: this.$t("display-type-options.display-without"), code: 1 },
          { name: this.$t("display-type-options.display-type"), code: 2 },
          { name: this.$t("display-type-options.display-both"), code: 3 },
        ];
      }
      if (this.isMedia) {
        return [
          { name: this.$t("display-type-options.show-list"), code: false },
          { name: this.$t("display-type-options.show-cards"), code: true },
        ];
      }
      if (this.appKey === "auditorium") {
        return [
          { name: this.$t("rooms.steps.welcome"), code: 0 },
          { name: this.$t("rooms.steps.details"), code: 1 },
          { name: this.$t("rooms.steps.preview"), code: 2 },
          { name: this.$t("rooms.steps.start"), code: 3 },
        ];
      }
      if (this.appKey === "map") {
        return [
          { name: this.$t("maps.settings.all"), code: 3 },
          { name: this.$t("maps.settings.community"), code: 0 },
        ];
      }
      return [];
    },
    displayVisibility() {
      return [
        {
          name: this.$t("addons.organizations.options.all-content"),
          code: "all",
        },
        {
          name: this.$t("addons.organizations.options.no-content"),
          code: "none",
        },
      ];
    },
    displayCollaboratorOptions() {
      // La opción que se quiera por defecto tiene que ser la primera del array.
      if (this.isMedia) {
        return [
          {
            name: this.$t("display-type-options.show-collaborator"),
            code: true,
          },
          {
            name: this.$t("display-type-options.dont-show-collaborator"),
            code: false,
          },
        ];
      }
      return [];
    },
    types() {
      if (this.appKey === "events") {
        const types = [];

        if (this.item.customizations?.displayOptions === 1) {
          types.push({
            name: "present",
            key: 1,
            namePlural: { ca: "Actuals", es: "Actuales", en: "Present" },
            customizations: this.item.customizations?.[1],
          });
          types.push({
            name: "futur",
            key: 3,
            namePlural: { ca: "Propers", es: "Próximos", en: "Upcoming" },
            customizations: this.item.customizations?.[3],
          });
          types.push({
            name: "past",
            key: 4,
            namePlural: { ca: "Passats", es: "Pasados", en: "Past" },
            customizations: this.item.customizations?.[4],
          });
        }

        if (this.item.customizations?.displayOptions === 2) {
          types.push({
            name: "present-futur",
            key: 2,
            namePlural: {
              ca: "Actuals i propers",
              es: "Actuales i próximos",
              en: "Present and upcoming",
            },
            customizations: this.item.customizations?.[2],
          });
          types.push({
            name: "past",
            key: 4,
            namePlural: { ca: "Passats", es: "Pasados", en: "Past" },
            customizations: this.item.customizations?.[4],
          });
        }

        return types;
      }
      return this.$store.getters.types?.[this.appKey]?.unpaginated || [];
    },
    customsToGet() {
      const arrayToShow = {
        ...this.item.customizations,
        name: { ...this.item.name },
        customizationName: {},
      };
      if (this.types.length > 0) {
        for (const row of this.types) {
          const ob = {
            type: row.key,
            name: row.namePlural,
            customizations: this.item.customizations?.[row.key],
          };
          arrayToShow.customizationName[row.key] = ob;
        }
        // arrayToShow.push(ob);
      }
      if (
        ["entities", "people", "organizations", "challenges"].includes(
          this.appKey
        ) &&
        this.types.length === 0 &&
        this.item.customizations.displayOptions !== 2
      ) {
        const ob = {
          type: this.appKey === "challenges" ? 0 : "noKey",
          name: this.item.name,
          customizations: this.item.customizations?.customizationName,
        };
        if (ob.customizations && ob.customizations.displayOptions) {
          delete ob.customizations.displayOptions;
        }
        arrayToShow.customizationName.noKey = ob;
      }
      return arrayToShow;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    Placeholder() {
      return Placeholder;
    },
    appsWithTypes() {
      return [
        "people", 
        "entities", 
        "organizations", 
        "members", 
        "challenges"
      ];
    },
    hasDisplayOptions() {
      return this.appsWithDisplayOptions.includes(this.appKey);
    },
    isMedia() {
      return this.mediaApps.includes(this.appKey);
    },
  },
  watch: {
    displayOptionSelected() {
      return this.customsToGet;
    },
    isOrganization() {
      return this.item?.key === "organizations";
    },
    isMember() {
      return this.item?.key === "members";
    },
  },
  async created() {
    this.isLoading = true;
    //check if we already have this addon data
    if(!this.$store.getters.addons?.unpaginated?.length){
      await this.fetchData();
    }
    //Get types
    if (this.appsWithTypes.includes(this.appKey) && this.types == null) {
      await this.$store.dispatch("getItems", {
          itemType: "types",
          storedKey: this.appKey,
          page: 1,
          perPage: 200,
          requestConfig: {
            getCommunityTypes: 1,
            modelType: this.appKey,
          },
      });
    }
    //await this.getInstalledApps();    //para que?

    //Map settings:
    if(this.appKey === "map"){
      if (this.enabledOrganizationApp) {
        this.displayOptions.push({
          name: this.$t("maps.settings.organizations"),
          code: 1,
        });
      }
      if (this.enabledEventsApp) {
        this.displayOptions.push({
          name: this.$t("maps.settings.events"),
          code: 2,
        });
      }
      if (this.enabledMembersApp) {
        this.displayOptions.push({
          name: this.$t("maps.settings.members"),
          code: 4,
        });
      }
      if (this.enabledSocietiesApp) {
        this.displayOptions.push({
          name: this.$t("maps.settings.societies"),
          code: 5,
        });
      }
    }
    //Display options
    if (this.item && this.hasDisplayOptions) {
      //No customizations
      if (this.item.customizations == null) {
        this.item.customizations = {
          displayOptions: this.displayOptions[0]?.code,
        };
        if (this.isMedia) {
          this.item.customizations.collaboratorDisplay =
            this.displayCollaboratorOptions[0].code;
        }
      }
      // const custom = this.item.customizations
      this.displayOptionSelected = this.item.customizations.hasOwnProperty(
        "displayOptions"
      )
        ? this.item.customizations.displayOptions
        : this.item.customizations.mediaDisplay;
      //Map
      if (
        this.appKey === "map" &&
        !this.item.customizations.hasOwnProperty("displayOptions")
      ) {
        this.displayOptionSelected = this.isNew
          ? this.displayOptions[0].code
          : this.displayOptions[1].code;
      }
      //Media
      if (this.isMedia) {
        this.collaboratorOptionSelected =
          this.item.customizations.collaboratorDisplay;
      }
      //Organization or member
      if (this.isOrganization || this.isMember) {
        if(this.item?.customizations?.organizationView){
          this.organizationOptionSelected =
          this.item?.customizations?.organizationView;
        }
        if(!this.item.customizations.hasOwnProperty("displayOptions")){
          this.displayOptionSelected = this.displayOptions[0]?.code;
        }
      }
    }
    this.isLoading = false;
  },
  methods: {
    async getInstalledApps() {
      await this.$store.dispatch("getInstalledApps");
    },
    async changeToggleButton(key, type) {
      this.loading = true;
      const itemType =
        type === "Layout" || type === "Preview"
          ? `addons/toggle${type}`
          : `${type}Addon`;
      await this.$store.dispatch("editItem", {
        item: {
          itemType,
          customName: "addons",
          requestConfig: {
            addonKey: key,
          },
        },
      });
      if (type === "publish") {
        await this.fetchData(); //para que?
      }
      this.loading = false;
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData() {
      //get all available apps
      await this.$store.dispatch("getItems", {
        itemType: "addons",
        page: 1,
        forceAPICall: true,
        requestConfig: {
          count: 100,
          //orderByDate: -1,
          type: null,//"addons",
          //addonKey: this.appKey,
        },
      });
    },
    async changeTagline() {
      this.isSaving = true;
      const setting = {
        ...this.item.customizations,
        displayOptions: this.displayOptionSelected,
      };
      if (this.isMedia) {
        setting.collaboratorDisplay = this.collaboratorOptionSelected;
      }
      if (this.isOrganization || this.isMember) {
        setting.organizationView = this.organizationOptionSelected;
      }
      try {
        await this.$store.dispatch("changeTaglineApp", {
          appKey: this.appKey,
          customization: setting,
        });
        this.isSaving = false;
        this.keyToRerender = randomString(10);

        this.notifySuccess(this.$t("integrations.messages.success"));
      } catch {
        this.notifyError(this.$t("integrations.messages.error"));
      }
    },

    async handleRemoveItem(response) {
      if (response === true) {
        try {
          this.isDeleting = true;
          await this.$store.dispatch("changeTaglineApp", {
            appKey: this.appKey,
            deleteCustomizationName: true,
          });

          delete this.item.customizations.customizationName;
          this.isDeleting = false;
          this.notifySuccess(this.$t("layouts.message.saved-success"));
        } catch {
          this.isDeleting = false;
          this.notifyError(this.$t("error-message.general-error"));
        }
      }
    },
    openModal(type) {
      this.isEditingName = true;
      this.editingType = type;
    },
    getDescription(item){
      return item.headline[this.currentLocale] || Object.values(item.headline)[0];
    }
  },
};
</script>
<style scoped>
/* TODO: fix responsive with bootstrap, better than media queries!! */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
}

.card-img {
  height: 150px !important;
}
.card-blog {
  height: 320px;
  width: 350px !important;
}
.inMenu {
  margin-left: -20px;
}
.actions-container {
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 15px
}

.special-action {
  border-bottom: 1px solid lightgrey;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
</style>
